import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Role, useCreateRoleMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function (role: Role) {
  const mutation = useCreateRoleMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    return mutation.mutate(
      {
        input: {
          name: role.name ?? "",
          description: role.description ?? "",
          attribute: role.attribute ?? "",
          applicationId: role.application?.id ?? undefined,
          organizationsIds: role.assignees?.map((element) => element?.id),
        },
      },
      {
        update: (cache, { data: data }) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              roles: {
                ...cachedData?.roles,
                totalCount: cachedData?.roles?.totalCount ?? 0 + 1,
                nodes: [
                  ...(cachedData?.roles?.nodes ?? []),
                  data?.roleMutation?.createRole,
                ],
              },
            },
          });

          // As application role count is now updated, delete the application cache.
          useDeleteFromCache(cache, "applications");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
