






















import Table from "@/components/Table.vue";
import useNavigateToOrganization from "@/composables/navigation/useNavigateToOrganization";
import useRemoveOption from "@/composables/options/useRemoveOption";
import useShowOption from "@/composables/options/useShowOption";
import { Organization, useGetRoleAssigneesQuery } from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { EditableKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, toRef, watch } from "@vue/composition-api";
import useRoleAssigneeMutations from "../Composables/useRoleAssigneeMutations";
import { IsNewKey, RoleKey } from "../symbols";
import AssignAssigneePopup from "./AssignAssigneePopup.vue";

export default defineComponent({
  components: { Table, AssignAssigneePopup },
  setup(props, { root }) {
    // Refs

    const role = injectStrict(RoleKey);
    const isNew = injectStrict(IsNewKey);
    const assignees = toRef(role, "assignees");
    const editable = injectStrict(EditableKey);

    // Graphql
    const { result, loading, error } = useGetRoleAssigneesQuery(
      () => ({ id: role.id }),
      () => ({ enabled: !isNew.value, fetchPolicy: "no-cache" })
    );

    const { removeAssignee } = useRoleAssigneeMutations(role.id);

    // Functions
    function addedRoleAssignee(item: Organization) {
      assignees.value = [...(assignees.value ?? []), item];
    }

    function removedRoleAssignee(item: Organization) {
      assignees.value =
        role.assignees?.filter((element) => element?.id != item.id) ?? [];
    }

    async function removeRoleAssignee(item: Organization) {
      if (role.id != null && role.id != "") {
        var result = await removeAssignee(item);
        if (result?.errors == null) removedRoleAssignee(item);
      } else {
        removedRoleAssignee(item);
      }
    }

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (!isNew.value) assignees.value = newValue?.assignees ?? [];
      },
      { immediate: true }
    );

    return {
      role,
      assignees,
      loading,
      error,
      editable,
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          filter: true,
          sortable: true,
          label: root.$t("organizations.fields.name").toString(),
        },
        {
          key: "vat",
          filter: true,
          sortable: true,
          label: root.$t("organizations.fields.vat").toString(),
        },
      ]),
      options: computed<IMenu<Organization>[]>(() => [
        {
          ...useShowOption(root.$tc("organization.title")),
          command: (organization) =>
            useNavigateToOrganization(root.$router, organization?.id),
        },
        {
          ...useRemoveOption(root.$tc("organization.title")),
          command: (organization) =>
            organization != null ? removeRoleAssignee(organization) : null,
          disabled: () => !editable.value,
        },
      ]),
      addedRoleAssignee,
      removedRoleAssignee,
    };
  },
});
