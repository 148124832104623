import {
  AssignUserRoleInput,
  useAssignRoleToUserMutation,
  useRemoveRoleFromUserMutation,
  useUpdateRoleAssignmentMutation,
} from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { computed } from "@vue/composition-api";
import { ParentQueryKey } from "../symbols";

export default function () {
  const addMutation = useAssignRoleToUserMutation({});
  const removeMutation = useRemoveRoleFromUserMutation({});
  const editMutation = useUpdateRoleAssignmentMutation({});

  const parentQuery = injectStrict(ParentQueryKey);

  async function add(assignment: AssignUserRoleInput) {
    return addMutation.mutate(
      {
        input: {
          ...assignment,
        },
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [parentQuery],
      }
    );
  }

  async function remove(
    roleId: string,
    userId: string,
    onBehalfOfOrganizationId: string
  ) {
    return removeMutation.mutate(
      {
        input: {
          roleId: roleId,
          userId: userId,
          onBehalfOfOrganizationId: onBehalfOfOrganizationId,
        },
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [parentQuery],
      }
    );
  }

  async function edit(
    roleId: string,
    userId: string,
    organizationId: string,
    expirationDate: any
  ) {
    return await editMutation.mutate({
      input: {
        roleId: roleId,
        userId: userId,
        onBehalfOfOrganizationId: organizationId,
        expirationTime: expirationDate,
      },
    });
  }

  return {
    loading: computed(
      () => addMutation.loading.value || removeMutation.loading.value
    ),
    add,
    remove,
    edit,
  };
}
