





































import InputGroup from "@/components/InputGroup.vue";
import Select from "@/components/Select.vue";
import ApplicationSelector from "@/components/Selectors/ApplicationSelector.vue";
import {
  useGetApplicationRolesLazyQuery,
  useRoleByAttributeLazyQuery,
} from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { usePromisable } from "@/utilities/useLazyApolloQuery";
import { required } from "@/utilities/validators";
import { defineComponent, toRefs, watch } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import useValidations from "../Composables/useValidations";
import { IsNewKey, RoleKey } from "../symbols";

export default defineComponent({
  components: { InputGroup, Select, ApplicationSelector },
  setup() {
    const role = injectStrict(RoleKey);
    const isNew = injectStrict(IsNewKey);

    const { fetch: fetchRoleByAttribute } = usePromisable(
      useRoleByAttributeLazyQuery(() => ({
        attribute: role.attribute ?? "",
      }))
    );

    const { fetch: fetchApplicationRoles } = usePromisable(
      useGetApplicationRolesLazyQuery(() => ({
        ID: role.application?.id ?? "",
      }))
    );

    const validAttributeValidator = async () => {
      if (
        !v$.value.attribute.$dirty ||
        role.attribute === null ||
        role.attribute === ""
      )
        return true;

      return !role.attribute?.includes("~") ?? true;
    };

    const v$ = useVuelidate(
      {
        ...useValidations(
          uniqueAttributeValidator,
          uniqueNameValidator,
          validAttributeValidator
        ),
        application: { required },
      },
      { ...toRefs(role) },
      { $autoDirty: true, $lazy: true }
    );

    async function uniqueAttributeValidator() {
      if (
        !v$.value.attribute.$dirty ||
        role.attribute == "" ||
        !v$.value.application.$model
      )
        return true;
      var result = await fetchApplicationRoles();
      if (
        result?.data.application?.roles?.nodes?.some(
          (element) =>
            element?.attribute?.toLowerCase() ===
              role.attribute?.toLowerCase() && element?.id != role.id
        )
      )
        return false;
      return true;
    }

    async function uniqueNameValidator() {
      if (
        !v$.value.name.$dirty ||
        role.name == "" ||
        !v$.value.application.$model
      )
        return true;
      var result = await fetchApplicationRoles();
      if (
        result?.data.application?.roles?.nodes?.some(
          (element) =>
            element?.name?.toLowerCase() === role.name?.toLowerCase() &&
            element?.id != role.id
        )
      )
        return false;
      return true;
    }

    watch(
      () => role.application,
      () => {
        if (!isNew.value) return;
        role.name = "";
        role.attribute = "";
      }
    );

    const roleAttributeKeydown = (e: any) => {
      if (e?.key === "~") {
        e.preventDefault();
      }
    };

    return {
      v$,
      isNew,
      role,
      roleAttributeKeydown,
    };
  },
});
