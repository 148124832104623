import { Role, useUpdateRoleMutation } from "@/graphql/types";

export default function (role: Role) {
  const mutation = useUpdateRoleMutation({});

  // Cache handled by apollo
  function call() {
    return mutation.mutate({
      input: {
        id: role.id,
        name: role.name ?? "",
        description: role.description ?? "",
        attribute: role.attribute ?? "",
      },
    });
  }

  return {
    ...mutation,
    call,
  };
}
