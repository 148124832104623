




















































import InputGroup from "@/components/InputGroup.vue";
import Popover, { PopoverProps } from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import {
  AssignUserRoleInput,
  OrderDirection,
  useGetAssignableUsersQuery,
  User,
  UserField,
  UserFiltersInput,
  UserFilterType,
  UserRoleAssignment,
} from "@/graphql/types";
import Select from "@/components/Select.vue";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { useResult } from "@vue/apollo-composable";
import useProfile from "@/composables/useProfile";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useAssignButton from "@/composables/buttons/useAssignButton";
import IButton from "@/interfaces/IButton";
import ExpirationTimeSelector from "@/components/ExpirationTimeSelector.vue";
import useSaveButton from "@/composables/buttons/useSaveButton";
import useRoleAssignmentMutations from "../Composables/useRoleAssignmentMutations";

export default defineComponent({
  components: { Popover, Table, InputGroup, Select, ExpirationTimeSelector },
  emits: ["assignedRole", "updatedRole"],
  props: {
    ...PopoverProps,
    roleId: {
      type: String,
      required: true,
    },
    userRoleAssignments: {
      type: Array as PropType<UserRoleAssignment[]>,
      default: () => [],
    },
  },
  setup(props, { root, emit }) {
    const { isCentralAdmin } = useProfile();
    const isEdit = ref(false);

    const show = ref(false);

    const filters = ref<UserFiltersInput>({
      filterType: isCentralAdmin.value
        ? UserFilterType.All
        : UserFilterType.Organization,
      includingTestUser: true,
      email: "",
    });

    const { result, loading } = useGetAssignableUsersQuery(
      () => ({
        filters: filters.value,
        sortInfo: {
          direction: OrderDirection.Asc,
          field: UserField.Email,
        },
        pageParams: {
          pageSize: 100, // currently using get users query with paging but this context does not need paging so we set a fixed pageSize to get users at once, maybe change later to adapt paging
          currentPage: 1,
        },
      }),
      () => ({
        enabled: (filters.value.email ?? "")?.length >= 3,
        fetchPolicy: "no-cache",
      })
    );

    const showWarning = computed(() => {
      const totalCount = useResult(result, 0, (data) => data.users.totalCount);
      return totalCount.value > 100 ? true : false;
    });

    const selectableUsers = useResult(result, [], (data) =>
      data.users.nodes.filter((x) => x.organization)
    );

    const disabledUsers = computed(() =>
      selectableUsers.value.filter((user) =>
        props.userRoleAssignments.some(
          (assignment) =>
            assignment.user?.id == user.id &&
            assignment.organization?.id == user.organization?.id
        )
      )
    );

    const originalUserAssignment = ref(null as UserRoleAssignment | null);
    const assignment = reactive<AssignUserRoleInput>({
      roleId: props.roleId,
      userId: "",
      expirationTime: undefined,
      isOBOAssignment: false,
    });

    const {
      add,
      edit,
      loading: mutationLoading,
    } = useRoleAssignmentMutations();

    async function assignUser() {
      const result = await add(assignment);
      if (result?.errors == null) {
        emit("assignedRole", assignment);
        show.value = false;
      }
    }

    async function updateRole() {
      const result = await edit(
        props.roleId,
        assignment.userId,
        originalUserAssignment.value?.organization?.id,
        assignment.expirationTime
      );
      if (result?.errors == null) {
        emit("updatedRole");
        show.value = false;
      }
    }

    function editAssignment(userRoleAssignment: UserRoleAssignment) {
      originalUserAssignment.value = userRoleAssignment;
      assignment.roleId = userRoleAssignment.role?.id;
      assignment.userId = userRoleAssignment.user?.id;
      assignment.expirationTime = userRoleAssignment.expirationDate;
      isEdit.value = true;
      show.value = true;
    }

    watch(
      () => show.value,
      (newValue) => {
        if (!newValue) {
          isEdit.value = false;
          originalUserAssignment.value = null;
          Object.assign(assignment, {
            userId: "",
            expirationTime: undefined,
          });
        }
      }
    );

    const user = computed<User | null>({
      get(): User | null {
        return isEdit.value
          ? originalUserAssignment.value?.user ?? null
          : selectableUsers.value.find(
              (element) => element.id == assignment.userId
            ) ?? null;
      },
      set(newValue: User | null): void {
        assignment.userId = newValue?.id;
      },
    });

    // customized select2 option template
    const templateResult = (option: any) => {
      let html = "";
      html += "<div>" + option?.object?.fullname + "</div>";
      html += "<small>" + option?.object?.email + "</small>";
      html += "</br>";
      html += "<small>" + option?.object?.organization?.vat + "</small>";
      return html;
    };

    return {
      isEdit,
      show,
      filters,
      showWarning,
      assignment,
      editAssignment,
      user,
      loading,
      templateResult,
      selectableUsers,
      disabledUsers,
      primaryButton: computed<IButton>(() => {
        return {
          ...(isEdit.value
            ? useSaveButton(() => updateRole())
            : useAssignButton(() => assignUser())),
          disabled:
            mutationLoading.value ||
            user.value == null ||
            (isEdit.value &&
              originalUserAssignment.value?.expirationDate ==
                assignment.expirationTime),
        };
      }),
      secondaryButton: useCancelButton(() => (show.value = false)),
    };
  },
});
