import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Role, useDeleteRoleMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function (role: Role) {
  const mutation = useDeleteRoleMutation({});
  const parentQuery = injectStrict(ParentQueryKey);
  function call() {
    return mutation.mutate(
      {
        id: role.id,
      },
      {
        update: (cache) => {
          const cachedData = cache.readQuery(parentQuery);

          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              roles: {
                ...cachedData?.roles,
                totalCount: cachedData?.roles?.totalCount ?? 0 - 1,
                nodes: cachedData?.roles?.nodes?.filter(
                  (element) => element?.id != role.id
                ),
              },
            },
          });

          // Need to update applications as number of role is updated
          useDeleteFromCache(cache, "applications");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
