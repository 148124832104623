


























import Sidebar, { useConfirmDelete } from "@/components/Sidebar.vue";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import Tapbar from "@/components/Tapbar.vue";
import CreateWizard from "@/components/CreateWizard.vue";
import ParentView from "@/components/ParentView.vue";
import { Role } from "@/graphql/types";
import { IsNewKey, RoleKey } from "./symbols";
import IStep from "@/interfaces/IStep";
import ITabContent from "@/interfaces/ITabContent";
import RoleCreated from "./Components/RoleCreated.vue";
import RoleData from "./Components/RoleData.vue";
import useVuelidate from "@vuelidate/core";
import RoleOrganizations from "./Components/RoleOrganizations.vue";
import useCreateRoleMutation from "./Composables/useCreateRoleMutation";
import useUpdateRoleMutation from "./Composables/useUpdateRoleMutation";
import useDeleteRoleMutation from "./Composables/useDeleteRoleMutation";
import { useGetRoleQuery } from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { useResult } from "@vue/apollo-composable";
import useProfile from "@/composables/useProfile";
import RoleUsers from "@/views/Roles/Components/RoleUsers.vue";
import { EditableKey, IdKey } from "@/symbols";
import useNextStepButton from "@/composables/buttons/useNextStepButton";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useGobackButton from "@/composables/buttons/useGobackButton";
import useSaveButton from "@/composables/buttons/useSaveButton";
import ChangelogTableVue from "@/components/ChangelogTable.vue";
import useGoToParent from "@/composables/useGoToParent";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  components: { Sidebar, Tapbar, CreateWizard, ParentView },
  setup(props, { root }) {
    const { isCentralAdmin, isSupportAdmin } = useProfile();
    const editable = computed(() => isCentralAdmin.value ?? false);

    // Refs
    const isNew = ref(props.id == null);
    const currentTab = ref(0);
    const hasChanges = ref<boolean | null>(null);
    if (isNew.value) hasChanges.value = false;

    const v$ = useVuelidate();
    const role = reactive<Role>({
      id: "",
      attribute: "",
      name: "",
      description: "",
      application: undefined,
      assignees: [],
    });

    provide(RoleKey, role);
    provide(IdKey, toRef(role, "id"));
    provide(IsNewKey, isNew);
    provide(EditableKey, editable);

    // Data
    const { result, loading } = useGetRoleQuery(
      () => ({ id: props.id }),
      () => ({
        enabled: !isNew.value,
        fetchPolicy: "no-cache",
      })
    );

    const createMutation = useCreateRoleMutation(role);
    const updateMutation = useUpdateRoleMutation(role);
    const deleteMutation = useDeleteRoleMutation(role);

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue) Object.assign(role, newValue);
        v$.value.$reset();
      },
      { immediate: true }
    );

    watch(
      () => [role.application?.id, role.name, role.attribute, role.description],
      () => {
        if (loading.value) return;
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    createMutation.onDone(({ data }) => {
      role.id = data?.roleMutation?.createRole?.id ?? "";
      currentTab.value++;
    });

    updateMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    deleteMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    return {
      isNew,
      currentTab,
      role,
      loading: computed(
        () =>
          loading.value ||
          deleteMutation.loading.value ||
          createMutation.loading.value ||
          updateMutation.loading.value
      ),
      menuItems: computed<IMenu[]>(() =>
        isNew.value
          ? []
          : [
              {
                name: root.$tc("roles.delete"),
                icon: "trash-alt",
                disabled: () => !editable.value,
                command: async () => {
                  if (await useConfirmDelete(role.name ?? "", role.name ?? ""))
                    deleteMutation.call();
                },
              },
            ]
      ),
      tabs: computed<ITabContent[]>(() =>
        isNew.value
          ? [
              {
                title: root.$tc("common.steps.data"),
                component: RoleData,
                primaryButton: {
                  ...useNextStepButton(async () => {
                    if (await v$.value.$validate()) currentTab.value++;
                  }),
                  disabled: !hasChanges.value || v$.value.$errors.length > 0,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("roles.steps.organization_access"),
                component: RoleOrganizations,
                primaryButton: {
                  title: root.$tc("roles.create"),
                  variant: "success",
                  onClick: () => createMutation.call(),
                },
                secondaryButton: useGobackButton(() => {
                  v$.value.$reset();
                  currentTab.value--;
                }),
              },
              {
                title: root.$tc("common.steps.done"),
                component: RoleCreated,
              },
            ]
          : [
              {
                title: root.$tc("common.steps.data"),
                component: RoleData,
                primaryButton: {
                  ...useSaveButton(async () => {
                    if (await v$.value.$validate()) updateMutation.call();
                  }),
                  disabled: !hasChanges.value || v$.value.$errors.length > 0,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("roles.steps.organization_access"),
                component: RoleOrganizations,
                visible: editable.value || isSupportAdmin.value,
              },
              {
                title: root.$tc("roles.steps.users"),
                component: RoleUsers,
                supportsFullscreen: true,
              },
              {
                title: root.$tc("history.title"),
                component: ChangelogTableVue,
                visible: isCentralAdmin.value || isSupportAdmin.value,
              },
            ]
      ),
      steps: computed<IStep[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          icon: "database",
        },
        {
          title: root.$tc("roles.steps.access"),
          icon: "share-alt",
        },
        {
          title: root.$tc("common.steps.done"),
          icon: "check",
        },
      ]),
    };
  },
});
