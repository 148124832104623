import IMenu from "@/interfaces/IMenu";

export default function <T>(entityName: string): IMenu<T> {
  return {
    i18nAction: "common.edit",
    name: entityName,
    command: () => undefined,
    icon: "pen",
  };
}
