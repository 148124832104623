












import { Application, useSelectApplicationQueryQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Select from "../Select.vue";

export default defineComponent({
  components: {
    Select,
  },
  props: {
    value: {
      type: Object as PropType<Application>,
    },
  },
  setup(props, { emit }) {
    const { result, loading } = useSelectApplicationQueryQuery();

    return {
      loading,
      item: computed<Application | undefined>({
        get() {
          return props.value;
        },
        set(newValue: Application | undefined): void {
          emit("input", newValue);
        },
      }),
      items: useResult(result, [], (data) => data.applications.nodes),
    };
  },
});
