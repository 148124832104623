



































































import Popover from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import {
  Organization,
  useGetSelectableOrganizationsQuery,
} from "@/graphql/types";
import { EditableKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import useRoleAssigneeMutations from "../Composables/useRoleAssigneeMutations";

export default defineComponent({
  components: { Popover, Table },
  emits: ["assignedOrganization", "removedOrganization"],
  props: {
    roleId: {
      type: String,
      required: false,
    },
    assignees: {
      type: Array as PropType<Organization[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const editable = injectStrict(EditableKey);

    // Data
    const show = ref(false);
    const { result, loading } = useGetSelectableOrganizationsQuery();

    const mutations = useRoleAssigneeMutations(props.roleId ?? "");

    // Functions
    async function addAssignee(organization: Organization) {
      if (props.roleId != null && props.roleId != "") {
        var result = await mutations.addAssignee(organization);
        if (result?.errors == null) emit("assignedOrganization", organization);
      } else {
        emit("assignedOrganization", organization);
      }
    }

    async function removeAssignee(organization: Organization) {
      if (props.roleId != null && props.roleId != "") {
        var result = await mutations.removeAssignee(organization);
        if (result?.errors == null) emit("removedOrganization", organization);
      } else {
        emit("removedOrganization", organization);
      }
    }

    function isAssigned(organization: Organization) {
      return props.assignees.some((element) => element.id == organization.id);
    }

    return {
      editable,
      show,
      loading,
      mutationLoading: mutations.loading,
      organizations: useResult(result, [], (data) => data.organizations.nodes),
      addAssignee,
      removeAssignee,
      fields: computed<TableField[]>(() => [
        { key: "name", filter: true, focus: true },
      ]),
      isAssigned,
    };
  },
});
