import {
  Organization,
  useAddRoleAssigneeMutation,
  useRemoveRoleAssigneeMutation,
} from "@/graphql/types";
import { computed } from "@vue/composition-api";

export default function (roleId: string) {
  const addMutation = useAddRoleAssigneeMutation({});
  const removeMutation = useRemoveRoleAssigneeMutation({});

  async function addAssignee(organization: Organization) {
    return addMutation.mutate({
      input: {
        organizationId: organization.id,
        roleId: roleId,
      },
    });
  }

  async function removeAssignee(organization: Organization) {
    return removeMutation.mutate({
      input: {
        organizationId: organization.id,
        roleId: roleId,
      },
    });
  }

  return {
    addAssignee,
    removeAssignee,
    loading: computed(
      () => addMutation.loading.value || removeMutation.loading.value
    ),
  };
}
