









































































import Table from "@/components/Table.vue";
import {
  useGetRoleAssignedUsersQuery,
  UserRoleAssignment,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { RoleKey } from "../symbols";
import AssignUserPopup from "./AssignUserPopup.vue";
import useProfile from "@/composables/useProfile";
import Tooltip from "@/components/Tooltip.vue";
import useNavigateToUser from "@/composables/navigation/useNavigateToUser";
import useCanNavigate from "@/composables/useCanNavigate";
import useCanRemove from "@/composables/useCanRemove";
import {
  useFromNowDateFormatted,
  useHasPassed,
} from "@/composables/useDateFormat";
import useRoleAssignmentMutations from "../Composables/useRoleAssignmentMutations";
import useEditOption from "@/composables/options/useEditOption";
import useRemoveOption from "@/composables/options/useRemoveOption";
import useShowOption from "@/composables/options/useShowOption";
import { FullScreenKey } from "@/symbols";

/*
  Description: This role-users component is used to show list of users being assigned with the role via a table. The table has 3 defined columns sush as user's full name, organization and on behalf of. Currently, we only have an action "Show User".

  Data: 
    role - Injected and used to get list of users by role id
    users - List of users assigned with the role being shown on the table
    loading - Indicate if the table is fetching users assigned with the role
    error - GraphQL call error
    tableFields - Defined table fields
    options - Indicate actions allowed in the table

  Methods:
  
*/

export default defineComponent({
  components: { Table, AssignUserPopup, Tooltip },
  setup(_, { root }) {
    const popup = ref<InstanceType<typeof AssignUserPopup>>();
    const fullscreen = injectStrict(FullScreenKey);

    const { isCentralAdmin, isLocalAdmin, isAppAdmin, isHomeOrg } =
      useProfile();

    const editable = computed<boolean>(
      () => isCentralAdmin.value || isLocalAdmin.value || isAppAdmin.value
    );

    //#region Injection
    const role = injectStrict(RoleKey);
    //#endregion

    //#region Graphql calls

    // Get list of users being assigned with the current role
    const { refetch, result, loading, error } = useGetRoleAssignedUsersQuery(
      () => ({ id: role.id }),
      () => ({
        fetchPolicy: "no-cache",
        enabled: role.id != null || role.id != "",
      })
    );

    const mutations = useRoleAssignmentMutations();

    async function removeUserAssignment(
      userId: string,
      onBehalfOfOrganizationId: string
    ) {
      await mutations
        .remove(role.id, userId, onBehalfOfOrganizationId)
        .then(() => {
          refetch();
        });
    }

    return {
      popup,
      editable,
      role,
      error,
      users: useResult(result, [], (data) => data.role.assignedUsers.nodes),
      loading: loading,
      tableFields: computed<TableField[]>(() => [
        {
          key: "user.fullname",
          filter: true,
          sortable: true,
          label: root.$tc("users.fields.name"),
        },
        {
          key: "expirationDate",
          label: "",
          width: "10%",
        },
        {
          key: "user.organization.name",
          filter: true,
          sortable: true,
          label: root.$tc("users.fields.organisation"),
        },
        {
          key: "organization.name",
          filter: true,
          sortable: true,
          label: root.$tc("roles.assignment.on_behalf_of"),
        },
        ...(fullscreen.value
          ? [
              {
                key: "user.lastLogin",
                label: root.$tc("users.fields.lastSeen"),
                sortable: true,
                filter: false,
                width: "10%",
              },
              {
                key: "lastUse",
                label: root.$tc("users.fields.last_use"),
                sortable: true,
                filter: false,
                width: "10%",
              },
            ]
          : []),
      ]),
      options: computed<IMenu<UserRoleAssignment>[]>(() => [
        {
          ...useEditOption(root.$tc("users.role_assignment")),
          command: (item) =>
            item ? popup.value?.editAssignment(item) : undefined,

          disabled: (item) =>
            !useCanRemove("UserRoleAssignment", { ...item, role: role }) ||
            useHasPassed(item?.expirationDate),
        },

        {
          ...useRemoveOption(root.$tc("users.role_assignment")),
          command: (item) =>
            item != null
              ? removeUserAssignment(item?.user?.id, item.organization?.id)
              : null,
          disabled: (item) =>
            !useCanRemove("UserRoleAssignment", { ...item, role: role }),
        },
        {
          ...useShowOption(root.$tc("user.title")),
          disabled: (item) =>
            !useCanNavigate("User", {
              ...item,
              role: role,
            }),
          command: (assignedUser) =>
            useNavigateToUser(root.$router, assignedUser?.user?.id),
        },
      ]),
      useHasPassed,
      useFromNowDateFormatted,
      updatedRole: () => refetch(),
      assignedRole: () => refetch(),
    };
  },
});
